import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import Btn from "../../../components/admin/Btn";
import CustomModal from "../../../components/common/CustomModal";
import useStores from "../../../hooks/store/useStores";
import SimplePagination from "../../../components/admin/SimplePagination";
import { useLogoDownload } from "../../../hooks/store/useLogoDownload";
import { useQRDownload } from "../../../hooks/store/useQRDownload";
import { useExcelDownload } from "../../../hooks/excel/useExcelDownload";
import StoreBoard from "../../../components/store/StoreBoard";
import { Suspense, useReducer, useEffect, useState, useCallback } from "react";
import { storeReducer } from "../../../reducers/store/storeReducer";
import DetailPagination from "../../../components/admin/DetailPagination";
import { transformYYYYMMDD } from "../../../function/date";

const initialStoreState = {
  storeName: "",
  ownerName: "",
  storeStatus: "",
  storeAcceptStatus: "",
};

export default function Stores() {
  const [modal, setModal] = useState({ showModal: false });
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [storeState, storeDispatch] = useReducer(
    storeReducer,
    initialStoreState
  );
  const [searchState, searchDispatch] = useReducer(
    storeReducer,
    initialStoreState
  );
  const { mutateExcel } = useExcelDownload("get");
  const { mutateLogo } = useLogoDownload();
  const { mutateQR } = useQRDownload();

  const setPageStatus = useCallback(() => {
    const [_, page, storeStatus, storeAcceptStatus, storeName, ownerName] =
      location.search
        .replace(
          /\?page=|&storeStatus=|&storeAcceptStatus=|&storeName=|&ownerName=/g,
          " "
        )
        .split(" ");

    if (!page) {
      setPage(0);
      searchDispatch({ type: "changed_storeName", storeName: "" });
      searchDispatch({ type: "changed_ownerName", ownerName: "" });
      searchDispatch({
        type: "changed_status",
        storeStatus: "",
      });
      searchDispatch({
        type: "changed_acceptStatus",
        storeAcceptStatus: "",
      });
      return;
    }

    setPage(page);
    searchDispatch({
      type: "changed_storeName",
      storeName: decodeURI(storeName),
    });
    searchDispatch({
      type: "changed_ownerName",
      ownerName: decodeURI(ownerName),
    });
    searchDispatch({
      type: "changed_status",
      storeStatus: decodeURI(storeStatus),
    });
    searchDispatch({
      type: "changed_acceptStatus",
      storeAcceptStatus: decodeURI(storeAcceptStatus),
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  // if (!stores) {
  //   return null;
  // }

  // const inquireStore = () => {
  //   const { storeStatus, storeAcceptStatus, storeName, ownerName } =
  //     storeSearchParams;

  //   navigate(
  //     `?page=0&storeStatus=${storeStatus}&storeAcceptStatus=${storeAcceptStatus}&storeName=${storeName}&ownerName=${ownerName}`
  //   );
  // };

  // function openDownloadModal() {
  //   const modalContent = {
  //     callbackOfOk: () =>
  //       downloadExcel({
  //         storeStatus: storeStatus,
  //         storeAcceptStatus: storeAcceptStatus,
  //       }),
  //     textOfBody: "엑셀를 다운로드 하시겠습니까?",
  //     showModal: true,
  //   };
  //   setModal(modalContent);
  // }
  // function downloadExcel(params) {
  //   const defaultParams = {
  //     page: 0,
  //     size: 10,
  //     storeStatus: "",
  //     storeAcceptStatus: "",
  //   };
  //   let requestParams = Object.assign({}, defaultParams, params);
  //   adminApi({
  //     url: "/stores/excel",
  //     params: requestParams,
  //     method: "get",
  //     customOption: { fileDownLoad: true },
  //     responseType: "arraybuffer",
  //   })
  //     .then(function (response) {
  //       console.log(response);
  //       const blob = new Blob([response.data]);
  //       const fileUrl = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = fileUrl;
  //       link.style.display = "none";
  //       link.download = getFileName(response);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     })
  //     .catch(function (error) {});
  // }
  // function getFileName(response) {
  //   const disposition = response.headers["content-disposition"];

  //   const fileName = decodeURI(
  //     disposition
  //       .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
  //       .replace(/['"]/g, "")
  //   );
  //   return fileName;
  // }

  const handleExcelDownBtnClick = () => {
    const { storeStatus, storeAcceptStatus, ownerName, storeName } =
      searchState;
    const modalContent = {
      callbackOfOk: () =>
        mutateExcel({
          url: "/stores/excel",
          params: {
            ...(ownerName && { ownerName }),
            ...(storeName && { storeName }),
            ...(storeStatus && { storeStatus }),
            ...(storeAcceptStatus && { storeAcceptStatus }),
          },
        }),
      textOfBody: "엑셀을 다운로드 하시겠습니까?",
      showModal: true,
    };

    setModal(modalContent);
  };

  const getNavigateStr = (state, page = 0) => {
    return `?page=${page}&storeStatus=${state.storeStatus}&storeAcceptStatus=${state.storeAcceptStatus}&storeName=${state.storeName}&ownerName=${state.ownerName}`;
  };

  const handleSearchBtnClick = () => {
    searchDispatch({
      type: "changed_storeName",
      storeName: storeState.storeName,
    });
    searchDispatch({
      type: "changed_ownerName",
      ownerName: storeState.ownerName,
    });
    searchDispatch({
      type: "changed_status",
      storeStatus: storeState.storeStatus,
    });
    searchDispatch({
      type: "changed_acceptStatus",
      storeAcceptStatus: storeState.storeAcceptStatus,
    });
    navigate(getNavigateStr(storeState));
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  const handleLogoDownBtnClick = (domain, logoUrl) => {
    mutateLogo({
      domain,
      logoUrl,
    });
  };

  const handleQRDownBtnClick = (domain) => {
    mutateQR(domain);
  };

  const handleInputKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    handleSearchBtnClick();
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">가맹점 목록 ver1</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElement}</h5>
              </Col>
              <Row className="p-1 justify-content-end">
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="엑셀 다운"
                    onClick={handleExcelDownBtnClick}
                  />
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "820px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          defaultValue={searchState.storeName}
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_storeName",
                              storeName: target.value,
                            })
                          }
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">사장님명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_ownerName",
                              ownerName: target.value,
                            })
                          }
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">운영 상태</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_status",
                              storeStatus: target.value,
                            })
                          }
                        >
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                defaultChecked
                                inline
                                label="전체"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-1`}
                                value=""
                              />
                              <Form.Check
                                inline
                                label="운영중"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-2`}
                                value="OPEN"
                              />
                              <Form.Check
                                inline
                                label="운영중단"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-3`}
                                value="PAUSE"
                              />
                              <Form.Check
                                inline
                                label="심사중"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-4`}
                                value="UNDER_REVIEW "
                              />
                              <Form.Check
                                inline
                                label="가맹점탈퇴"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-5`}
                                value="CLOSED"
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">승인 상태</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_acceptStatus",
                              storeAcceptStatus: target.value,
                            })
                          }
                        >
                          {["radio"].map((type) => (
                            <div
                              key={`inline-${type}`}
                              className="radiobox d-flex"
                            >
                              <Form.Check
                                defaultChecked
                                inline
                                label="전체"
                                name="Filter-2"
                                type={type}
                                id={`inline2-${type}-1`}
                                value=""
                              />
                              <Form.Check
                                inline
                                label="승인"
                                name="Filter-2"
                                type={type}
                                id={`inline2-${type}-2`}
                                value="ACCEPTED"
                              />
                              <Form.Check
                                inline
                                label="승인요청"
                                name="Filter-2"
                                type={type}
                                id={`inline2-${type}-3`}
                                value="REQUEST"
                              />
                              <Form.Check
                                inline
                                label="작성중"
                                name="Filter-2"
                                type={type}
                                id={`inline2-${type}-4`}
                                value="WRITING"
                              />
                              <Form.Check
                                inline
                                label="거절"
                                name="Filter-2"
                                type={type}
                                id={`inline2-${type}-5`}
                                value="REFUSED"
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4"
                responsive
              >
                <thead className="text-center">
                  <tr>
                    <th>store id</th>
                    <th>가맹점명</th>
                    <th>대표명</th>
                    <th>운영 상태</th>
                    <th>승인 상태</th>
                    <th>계좌 승인 상태</th>
                    <th>팝빌 연동</th>
                    {/* <th>상품 목록</th> */}
                    {/*<th>로고 다운로드</th>*/}
                    {/* <th>스토어 바로가기</th> */}
                    {/*<th>QR다운로드</th>*/}
                    <th>수수료</th>
                    <th>개설일</th>
                    <th>발행한도</th>
                    <th>주문형</th>
                    <th>패스방식</th>
                    <th>추가 알림톡 사용여부</th>
                    <th>자진발급</th>
                    <th>현장발행</th>
                    <th>대량발행</th>
                    <th>예약상품</th>
                    <th>출입관제</th>
                    <th>메뉴 주문 자동취소</th>
                    <th>키즈카페 솔루션</th>
                    <th>프로모션 코드</th>
                    {/*<th>테스트 유무</th>*/}
                  </tr>
                </thead>

                <tbody className="text-center" style={{ position: "relative" }}>
                  <Suspense
                    fallback={
                      <tr style={{ height: "250px" }}>
                        <Spinner
                          as={"td"}
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            marginLeft: "-18px",
                          }}
                          animation="border"
                        />
                      </tr>
                    }
                  >
                    <StoreBoard
                      setTotalElement={setTotalElement}
                      setTotalPage={setTotalPage}
                      searchState={searchState}
                      page={page}
                      onLogoDownBtnClick={handleLogoDownBtnClick}
                      onQRDownBtnClick={handleQRDownBtnClick}
                      setModal={setModal}
                    />
                  </Suspense>
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPage}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modal.showModal && (
        <CustomModal
          callbackOfOk={modal.callbackOfOk}
          textOfHeader={modal.textOfHeader}
          textOfBody={modal.textOfBody}
          setModal={setModal}
        />
      )}
    </>
  );
}
